import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Alert,
  LinearProgress,
  IconButton,
  TextField,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { uploadCsvFile } from '../../services/apiService';

const CsvUploadModal = ({ open, onClose, onUpload, provider }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [listName, setListName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setError(null);
      setSuccess(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Please select a file to upload');
      return;
    }

    if (!listName.trim()) {
      setError('Please enter a list name');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('listName', listName.trim());
    formData.append('clientId', JSON.parse(localStorage.getItem('auth'))._id);
    formData.append('provider', provider);

    try {
      const response = await uploadCsvFile(formData);
      if (response && response.status === 200) {
        onUpload(formData, response.data?.message);
        handleClose();
      } else {
        setError('Upload failed. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      const errorMessage = error.response?.status === 404 
        ? 'File not found or invalid format. Please check your CSV file.'
        : error.response?.status === 500
          ? 'Server error. Please try again later.'
          : error.response?.data?.message || 'Error uploading file. Please try again.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setListName('');
    setError(null);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={loading ? undefined : handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          backgroundColor: '#faf8f6'
        }
      }}
    >
      <DialogTitle sx={{ 
        color: '#5f6368',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff'
      }}>
        Upload Contact List
        {!loading && (
          <IconButton 
            onClick={handleClose}
            size="small"
            sx={{ color: '#5f6368' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {loading && (
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress 
              sx={{ 
                backgroundColor: 'rgba(46, 125, 50, 0.1)',
                '& .MuiLinearProgress-bar': {
                  background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
                }
              }} 
            />
          </Box>
        )}

        {error && (
          <Alert 
            severity="error"
            onClose={() => setError(null)}
            sx={{ 
              mb: 2,
              backgroundColor: 'rgba(211, 47, 47, 0.1)',
              color: '#D32F2F',
              '& .MuiAlert-icon': {
                color: '#D32F2F'
              }
            }}
          >
            {error}
          </Alert>
        )}

        {success && (
          <Alert 
            severity="success"
            sx={{ 
              mb: 2,
              backgroundColor: 'rgba(46, 125, 50, 0.1)',
              color: '#2E7D32',
              '& .MuiAlert-icon': {
                color: '#2E7D32'
              }
            }}
          >
            Contact list uploaded successfully!
          </Alert>
        )}

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          py: 3
        }}>
          <TextField
            fullWidth
            label="List Name"
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            required
            disabled={loading}
            placeholder="Enter a name for your contact list"
            sx={{
              '& .MuiOutlinedInput-root': {
                color: '#5f6368',
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2E7D32',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#5f6368',
                '&.Mui-focused': {
                  color: '#2E7D32',
                },
              },
            }}
          />

          <Button
            component="label"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            disabled={loading}
            sx={{
              color: '#5f6368',
              borderColor: 'rgba(0, 0, 0, 0.08)',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.12)',
                backgroundColor: 'rgba(0, 0, 0, 0.02)'
              }
            }}
          >
            Choose CSV File
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileSelect}
            />
          </Button>

          {selectedFile && (
            <Typography variant="body2" sx={{ color: '#5f6368' }}>
              Selected file: {selectedFile.name}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        p: 2, 
        borderTop: '1px solid rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff'
      }}>
        <Button
          onClick={handleClose}
          disabled={loading}
          sx={{ 
            color: '#5f6368',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          disabled={!selectedFile || !listName.trim() || loading}
          variant="contained"
          sx={{
            background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
            color: 'white',
            boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
            '&:hover': {
              background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)'
            }
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CsvUploadModal;
