import { SET_PROVIDER } from '../actions/types';

const initialState = {
  currentProvider: localStorage.getItem('provider') || 'plivo'
};

export default function providerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROVIDER:
      localStorage.setItem('provider', action.payload);
      return {
        ...state,
        currentProvider: action.payload
      };
    default:
      return state;
  }
}
