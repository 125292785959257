import {
  createCampaignApi,
  getCampaignsApi,
  getAssistantsApi
} from '../services/campaignService';

// Action Types
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE';
export const GET_ASSISTANTS = 'GET_ASSISTANTS';
export const GET_ASSISTANTS_SUCCESS = 'GET_ASSISTANTS_SUCCESS';
export const GET_ASSISTANTS_FAILURE = 'GET_ASSISTANTS_FAILURE';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';

// Action Creators
export const createCampaign = (campaignData) => async (dispatch) => {
  dispatch({ type: CREATE_CAMPAIGN });
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }

    const response = await createCampaignApi({
      ...campaignData,
      clientId: auth._id
    });

    dispatch({
      type: CREATE_CAMPAIGN_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (error) {
    console.error('Error creating campaign:', error);
    dispatch({
      type: CREATE_CAMPAIGN_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const getCampaigns = () => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGNS });
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }

    const response = await getCampaignsApi(auth._id);

    dispatch({
      type: GET_CAMPAIGNS_SUCCESS,
      payload: response.data
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    dispatch({
      type: GET_CAMPAIGNS_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const getAssistants = () => async (dispatch) => {
  dispatch({ type: GET_ASSISTANTS });
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }

    const response = await getAssistantsApi(auth._id);
    dispatch({
      type: GET_ASSISTANTS_SUCCESS,
      payload: response.data
    });

    return response;
  } catch (error) {
    console.error('Error fetching assistants:', error);
    dispatch({
      type: GET_ASSISTANTS_FAILURE,
      payload: error.message
    });
    throw error;
  }
};
