import {
  getContactListsApi,
  uploadCsvApi,
  retryContactUploadApi
} from '../services/contactService';

// Action Types
export const GET_CONTACT_LISTS = 'GET_CONTACT_LISTS';
export const GET_CONTACT_LISTS_SUCCESS = 'GET_CONTACT_LISTS_SUCCESS';
export const GET_CONTACT_LISTS_FAILURE = 'GET_CONTACT_LISTS_FAILURE';
export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_FAILURE = 'UPLOAD_CSV_FAILURE';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';

// Action Creators
export const getContactLists = () => async (dispatch) => {
  dispatch({ type: GET_CONTACT_LISTS });
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }

    const response = await getContactListsApi(auth._id);
    console.log('Contact Lists Response:', response);

    let lists = [];
    if (response?.data) {
      if (Array.isArray(response.data)) {
        lists = response.data;
      } else if (response.data.lists && Array.isArray(response.data.lists)) {
        lists = response.data.lists;
      }
    }

    dispatch({
      type: GET_CONTACT_LISTS_SUCCESS,
      payload: lists
    });

    return response; // Return the full response for more flexibility in handling
  } catch (error) {
    console.error('Error fetching contact lists:', error);
    dispatch({
      type: GET_CONTACT_LISTS_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const uploadCsv = (formData) => async (dispatch) => {
  dispatch({ type: UPLOAD_CSV });
  try {
    const response = await uploadCsvApi(formData);

    dispatch({
      type: UPLOAD_CSV_SUCCESS,
      payload: response.data
    });

    // Refresh contact lists after successful upload
    dispatch(getContactLists());
    
    return response.data;
  } catch (error) {
    console.error('Error uploading CSV:', error);
    dispatch({
      type: UPLOAD_CSV_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const retryContactUpload = (listId) => async (dispatch) => {
  try {
    const response = await retryContactUploadApi(listId);
    return response.data;
  } catch (error) {
    console.error('Error retrying contact upload:', error);
    throw error;
  }
};
