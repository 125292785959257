import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  Chip,
  Tabs,
  Tab,
  Tooltip,
  Menu,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Download as DownloadIcon,
  Chat as ChatIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { getCampaignsByClient, getReportByCampaign } from '../services/apiService';
import ProviderSelector from './helper/ProviderSelector';
import ChatModal from './modals/ChatModal';

const Report = () => {
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentProvider, setCurrentProvider] = useState(localStorage.getItem('provider') || 'plivo');
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  
  // New state for filters and tabs
  const [selectedTab, setSelectedTab] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [minDuration, setMinDuration] = useState('');
  const [chatSearch, setChatSearch] = useState('');
  const [sortDuration, setSortDuration] = useState('none');
  const [anchorEl, setAnchorEl] = useState(null);

  // Load campaigns on mount
  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        setLoading(true);
        setError(null);
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (!auth?._id) {
          throw new Error('No client ID found');
        }
        const data = await getCampaignsByClient(auth._id);
        if (Array.isArray(data)) {
          setCampaigns(data);
        } else {
          console.error('Invalid campaigns data:', data);
          setError('Invalid campaign data received');
        }
      } catch (error) {
        console.error('Error loading campaigns:', error);
        setError(error.message || 'Failed to load campaigns');
      } finally {
        setLoading(false);
      }
    };

    loadCampaigns();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
  };

  const handleDownloadCSV = () => {
    const filteredData = getFilteredReports();
    const csvData = filteredData.map(report => {
      const structuredData = JSON.parse(report.structuredOutputData || '{}');
      return {
        Name: report.firstName || 'Unknown',
        Conversation_Name: structuredData.name || 'N/A',
        From: report.caller_number,
        AI_Number: report.ai_number,
        Status: structuredData.hotLead ? 'Hot Lead' : 
               structuredData.warmLead ? 'Warm Lead' : 'Cold Lead',
        Duration: Math.round(report.conversation_time),
        'Date Created': formatMongoDate(report._id),
        Chat: report.chat,
        // 'Conversation Name': structuredData.name ? `Conversation with ${structuredData.first_name}` : 'N/A'
      };
    });

    const headers = Object.keys(csvData[0]);
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => headers.map(header => 
        JSON.stringify(row[header] || '')).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `report_${selectedTab === 0 ? 'all' : 
                              selectedTab === 1 ? 'hot_leads' : 
                              selectedTab === 2 ? 'warm_leads' : 
                              'cold_leads'}.csv`;
    link.click();
  };

  const getFilteredReports = () => {
    return reports.filter(report => {
      const structuredData = JSON.parse(report.structuredOutputData || '{}');
      const duration = Math.round(report.conversation_time || 0);
      
      // Basic search filter
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        const name = structuredData.name || '';
        if (!name.toLowerCase().includes(searchLower) &&
            !report.caller_number?.toLowerCase().includes(searchLower) &&
            !report.ai_number?.toLowerCase().includes(searchLower)) {
          return false;
        }
      }

      // Duration filter
      if (minDuration && duration < parseInt(minDuration)) {
        return false;
      }

      // Chat search filter
      if (chatSearch && !report.chat?.toLowerCase().includes(chatSearch.toLowerCase())) {
        return false;
      }

      // Tab filters
      switch (selectedTab) {
        case 1: // Hot Leads
          return structuredData.hotLead;
        case 2: // Warm Leads
          return structuredData.warmLead;
        case 3: // Cold Leads
          return !structuredData.hotLead && !structuredData.warmLead;
        default:
          return true;
      }
    }).sort((a, b) => {
      if (sortDuration === 'none') return 0;
      const durationA = Math.round(a.conversation_time || 0);
      const durationB = Math.round(b.conversation_time || 0);
      return sortDuration === 'asc' ? durationA - durationB : durationB - durationA;
    });
  };

  const filteredReports = useMemo(getFilteredReports, [
    reports, searchQuery, minDuration, chatSearch, selectedTab, sortDuration
  ]);

  const paginatedReports = useMemo(() => {
    return filteredReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredReports, page, rowsPerPage]);

  const formatMongoDate = (objectId) => {
    if (!objectId) return '';
    // Convert first 8 characters of ObjectId to timestamp
    const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
    try {
      return format(new Date(timestamp), 'dd MMM yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  // Load reports when campaign is selected
  const handleCampaignChange = async (event) => {
    const campaignId = event.target.value;
    setSelectedCampaign(campaignId);
    
    if (campaignId) {
      setLoading(true);
      setError(null);
      try {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const selectedCamp = campaigns.find(c => c._id === campaignId);
        const response = await getReportByCampaign(campaignId, selectedCamp?.name || '');
        
        if (response) {
          setReports(response);
        } else {
          setReports([]);
          setError('No reports found for this campaign');
        }
      } catch (error) {
        console.error('Error loading reports:', error);
        setError(error.message || 'Failed to load reports');
        setReports([]);
      } finally {
        setLoading(false);
      }
    } else {
      setReports([]);
    }
  };

  const handleViewChat = (chat) => {
    setSelectedChat(chat);
    setChatModalOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5" sx={{ color: '#5f6368', fontWeight: 500 }}>
          Campaign Reports
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <ProviderSelector 
            value={currentProvider} 
            onChange={(value) => setCurrentProvider(value)} 
          />
        </Box>
      </Box>

      <Box sx={{ 
        mb: 3,
        display: 'flex',
        gap: 2,
        alignItems: 'center'
      }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel sx={{ color: '#5f6368' }}>Select Campaign</InputLabel>
          <Select
            value={selectedCampaign}
            onChange={handleCampaignChange}
            label="Select Campaign"
            sx={{
              color: '#5f6368',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.08)'
              }
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {campaigns.map((campaign) => (
              <MenuItem key={campaign._id} value={campaign._id}>
                {campaign.name || campaign.campaignName || 'Unnamed Campaign'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          size="small"
          placeholder="Search by name or number..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#5f6368' }} />
              </InputAdornment>
            ),
          }}
          sx={{
            flex: 1,
            maxWidth: 300,
            '& .MuiOutlinedInput-root': {
              color: '#5f6368',
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.08)'
              }
            }
          }}
        />

        <Tooltip title="Filters">
          <IconButton 
            onClick={() => setFilterOpen(!filterOpen)}
            sx={{ 
              color: filterOpen ? '#1976d2' : '#5f6368',
              bgcolor: filterOpen ? 'rgba(25, 118, 210, 0.08)' : 'transparent'
            }}
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Download CSV">
          <IconButton
            onClick={handleDownloadCSV}
            disabled={!filteredReports.length}
            sx={{ 
              color: '#5f6368',
              '&:disabled': {
                color: 'rgba(95, 99, 104, 0.3)'
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {filterOpen && (
        <Paper sx={{ p: 2, mb: 3, bgcolor: '#ffffff' }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            size="small"
            label="Min Duration (seconds)"
            type="number"
            value={minDuration}
            onChange={(e) => setMinDuration(e.target.value)}
            sx={{ 
              width: 180,
              '& .MuiInputBase-input': {
                color: '#5f6368'
              },
              '& .MuiInputLabel-root': {
                color: '#5f6368'
              }
            }}
          />

          <TextField
            size="small"
            label="Search in Chat"
            value={chatSearch}
            onChange={(e) => setChatSearch(e.target.value)}
            sx={{ 
              width: 200,
              '& .MuiInputBase-input': {
                color: '#5f6368'
              },
              '& .MuiInputLabel-root': {
                color: '#5f6368'
              }
            }}
          />

          <FormControl size="small" sx={{ width: 200 }}>
            <InputLabel sx={{ color: '#5f6368' }}>Sort by Duration</InputLabel>
            <Select
              value={sortDuration}
              onChange={(e) => setSortDuration(e.target.value)}
              label="Sort by Duration"
              sx={{
                color: '#5f6368',
                '& .MuiSelect-icon': {
                  color: '#5f6368'
                }
              }}
            >
              <MenuItem value="none" sx={{ color: '#5f6368' }}>None</MenuItem>
              <MenuItem value="asc" sx={{ color: '#5f6368' }}>Shortest First</MenuItem>
              <MenuItem value="desc" sx={{ color: '#5f6368' }}>Longest First</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs 
          value={selectedTab} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              color: '#5f6368',
              '&.Mui-selected': {
                color: '#1976d2'
              }
            }
          }}
        >
          <Tab label="All" />
          <Tab label="Hot Leads" />
          <Tab label="Warm Leads" />
          <Tab label="Cold Leads" />
        </Tabs>
      </Box>

      <TableContainer component={Paper} sx={{ 
        mt: 3, 
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
        backgroundColor: '#faf8f6',
      }}>
        {error && (
          <Box sx={{ 
            p: 2, 
            color: '#d32f2f',
            bgcolor: 'rgba(211, 47, 47, 0.1)',
            borderRadius: '4px 4px 0 0'
          }}>
            {error}
          </Box>
        )}
        
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f3f1' }}>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Name</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>From</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>AI Number</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Conversation Name</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Status</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Duration</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Recording</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Date Created</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 500 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : paginatedReports.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} sx={{ textAlign: 'center', color: '#5f6368' }}>
                  {selectedCampaign ? 'No reports found' : 'Please select a campaign'}
                </TableCell>
              </TableRow>
            ) : (
              paginatedReports.map((report) => {
                const structuredData = JSON.parse(report.structuredOutputData || '{}');
                return (
                  <TableRow 
                    key={report._id}
                    sx={{ 
                      '&:hover': { 
                        backgroundColor: '#f5f3f1'
                      }
                    }}
                  >
                    <TableCell sx={{ color: '#5f6368' }}>
                      {report.firstName || 'Unknown'}
                    </TableCell>
                    <TableCell sx={{ color: '#5f6368' }}>{report.caller_number}</TableCell>
                    <TableCell sx={{ color: '#5f6368' }}>{report.ai_number}</TableCell>
                    <TableCell sx={{ color: '#5f6368' }}>
                      {structuredData.name ? structuredData.name : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={structuredData.hotLead ? 'Hot Lead' : 
                               structuredData.warmLead ? 'Warm Lead' : 'Cold Lead'}
                        size="small"
                        sx={{
                          backgroundColor: structuredData.hotLead ? 'rgba(211, 47, 47, 0.1)' : 
                                         structuredData.warmLead ? 'rgba(245, 124, 0, 0.1)' : 
                                         'rgba(158, 158, 158, 0.1)',
                          color: structuredData.hotLead ? '#d32f2f' : 
                                structuredData.warmLead ? '#f57c00' : 
                                '#757575'
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ color: '#5f6368' }}>
                      {Math.round(report.conversation_time)} seconds
                    </TableCell>
                    <TableCell>
                      {report.RecordUrl && (
                        <IconButton
                          size="small"
                          href={report.RecordUrl}
                          target="_blank"
                          sx={{ 
                            color: '#5f6368',
                            '&:hover': {
                              color: '#1976d2'
                            }
                          }}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell sx={{ color: '#5f6368' }}>
                      {formatMongoDate(report._id)}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleViewChat(report.chat)}
                        sx={{ 
                          color: '#5f6368',
                          '&:hover': {
                            color: '#1976d2'
                          }
                        }}
                      >
                        <ChatIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredReports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: '#5f6368',
            backgroundColor: '#f5f3f1',
            '& .MuiTablePagination-select': {
              color: '#5f6368'
            }
          }}
        />
      </TableContainer>

      <ChatModal
        open={chatModalOpen}
        onClose={() => {
          setChatModalOpen(false);
          setSelectedChat(null);
        }}
        chat={selectedChat}
      />
    </Box>
  );
};

export default Report;
