import { getProviderApi, getAssistants } from './apiService';

export const createCampaignApi = async (campaignData) => {
  const api = getProviderApi();
  return api.post('/create-campaign', campaignData);
};

export const getCampaignsApi = async (clientId) => {
  const currentProvider = localStorage.getItem('provider') || 'plivo';
  const api = getProviderApi();
  const endpoint = currentProvider === 'plivo' ? '/get-campaign-by-client' : '/get-camp-by-clientId';
  
  return api.post(endpoint, { clientId });
};

export const getAssistantsApi = async (clientId) => {
  return getAssistants(clientId);
};

export const updateAssistantApi = async (assistantId, agentFirstMessage, systemPrompt) => {
  const api = getProviderApi();
  return api.post('/interlogue/update-assistant', {
    assistantId,
    agentFirstMessage,
    systemPrompt
  });
};
