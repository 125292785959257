import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  Snackbar
} from '@mui/material';
import { setProvider } from '../../actions/providerActions';

const ProviderSelector = () => {
  const dispatch = useDispatch();
  const currentProvider = useSelector((state) => state.provider.currentProvider);
  const [exotelDialogOpen, setExotelDialogOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [exotelInfo, setExotelInfo] = React.useState({
    sid: '',
    token: '',
    accountSid: ''
  });

  const refreshWithDelay = (provider) => {
    setSnackbarOpen(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleProviderChange = (event) => {
    const newProvider = event.target.value;
    if (newProvider === 'exotel' && !localStorage.getItem('exotelInfo')) {
      setExotelDialogOpen(true);
    } else {
      dispatch(setProvider(newProvider));
      refreshWithDelay(newProvider);
    }
  };

  const handleExotelSubmit = () => {
    if (exotelInfo.sid && exotelInfo.token && exotelInfo.accountSid) {
      localStorage.setItem('exotelInfo', JSON.stringify(exotelInfo));
      dispatch(setProvider('exotel'));
      setExotelDialogOpen(false);
      refreshWithDelay('exotel');
    } else {
      alert('Please fill all Exotel information');
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <FormControl component="fieldset">
        <Box sx={{ 
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          color: '#5f6368'
        }}>
          {JSON.parse(localStorage.getItem('auth'))?.plivo === '1' && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                id="plivo"
                value="plivo"
                checked={currentProvider === 'plivo'}
                onChange={handleProviderChange}
                sx={{ color: '#5f6368', '&.Mui-checked': { color: '#5f6368' } }}
              />
              <label htmlFor="plivo" style={{ color: '#5f6368', cursor: 'pointer' }}>Plivo</label>
            </Box>
          )}
          {JSON.parse(localStorage.getItem('auth'))?.exotel === '1' && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Radio
                id="exotel"
                value="exotel"
                checked={currentProvider === 'exotel'}
                onChange={handleProviderChange}
                sx={{ color: '#5f6368', '&.Mui-checked': { color: '#5f6368' } }}
              />
              <label htmlFor="exotel" style={{ color: '#5f6368', cursor: 'pointer' }}>Exotel</label>
            </Box>
          )}
        </Box>
      </FormControl>

      <Dialog open={exotelDialogOpen} onClose={() => setExotelDialogOpen(false)}>
        <DialogTitle>Exotel Information Required</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="SID"
            fullWidth
            variant="outlined"
            value={exotelInfo.sid}
            onChange={(e) => setExotelInfo({ ...exotelInfo, sid: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Token"
            fullWidth
            variant="outlined"
            value={exotelInfo.token}
            onChange={(e) => setExotelInfo({ ...exotelInfo, token: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Account SID"
            fullWidth
            variant="outlined"
            value={exotelInfo.accountSid}
            onChange={(e) => setExotelInfo({ ...exotelInfo, accountSid: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setExotelDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleExotelSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          Switching to {currentProvider}... Page will refresh in 2 seconds
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProviderSelector;
