import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Add as AddIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { getAssistants } from '../services/apiService';
import './MyAI.css';
import EditAssistantModal from './modals/EditAssistantModal';

const MyAI = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const history = useHistory();

  const loadAgents = async () => {
    setLoading(true);
    try {
      const auth = JSON.parse(localStorage.getItem('auth'));
      const response = await getAssistants(auth._id);
      if (response?.data) {
        setAgents(response.data);
      }
    } catch (error) {
      console.error('Error fetching AI agents:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAgents();
  }, []);

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedAgent(null);
    loadAgents();
  };

  const handleCreateFlow = () => {
    history.push('/ai-flow');
  };

  const filteredAgents = agents.filter(agent => {
    const searchTermLower = searchTerm.toLowerCase();
    return agent.agent_name?.toLowerCase().includes(searchTermLower) ||
           agent._id?.toLowerCase().includes(searchTermLower);
  });

  return (
    <Box sx={{ 
      p: 3,
      position: 'relative',
      minHeight: '100vh',
    }}>
      <div className="dots-background" />
      <Box sx={{ 
        position: 'relative',
        zIndex: 1,
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3,
        }}>
          <Typography variant="h5" sx={{ color: '#5f6368', fontWeight: 500 }}>
            My AI Agents
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateFlow}
            sx={{
              background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
              color: 'white',
              boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
              },
              textTransform: 'none',
            }}
          >
            Create AI Flow
          </Button>
        </Box>

        <Box sx={{ 
          mb: 3,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
          <TextField
            size="small"
            placeholder="Search by name or ID..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: 300,
              bgcolor: '#ffffff',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                },
                '& input': {
                  color: '#5f6368',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#5f6368',
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#5f6368',
                opacity: 0.7,
              },
            }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: '#5f6368', mr: 1 }} />,
            }}
          />
        </Box>

        <Paper sx={{ 
          width: '100%',
          bgcolor: '#ffffff',
          borderRadius: 1,
          overflow: 'hidden',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>_ID</TableCell>
                  <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>Agent Name</TableCell>
                  <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>App ID</TableCell>
                  <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress size={24} sx={{ color: '#2E7D32' }} />
                    </TableCell>
                  </TableRow>
                ) : filteredAgents.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center" sx={{ color: '#5f6368' }}>
                      No AI agents found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredAgents.map((agent) => (
                    <TableRow 
                      key={agent._id}
                      sx={{ '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.02)' } }}
                    >
                      <TableCell sx={{ color: '#5f6368' }}>{agent._id}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{agent.agent_name}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{agent.appId}</TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleEdit(agent)}
                          sx={{
                            color: '#2E7D32',
                            '&:hover': {
                              bgcolor: 'rgba(46, 125, 50, 0.08)',
                            },
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {selectedAgent && (
        <EditAssistantModal
          open={isEditModalOpen}
          handleClose={handleCloseEditModal}
          assistant={selectedAgent}
        />
      )}
    </Box>
  );
};

export default MyAI;
