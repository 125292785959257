import { fetchBillingHistory as fetchBillingHistoryAPI, fetchClientData as fetchClientDataAPI } from "../services/apiService";

// Action types
export const FETCH_BILLING_HISTORY_SUCCESS = 'FETCH_BILLING_HISTORY_SUCCESS';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_BILLING_ERROR = 'FETCH_BILLING_ERROR';
export const FETCH_CLIENT_ERROR = 'FETCH_CLIENT_ERROR';

// Fetch billing history action
export const fetchBillingHistory = (clientId) => async (dispatch) => {
    try {
        const response = await fetchBillingHistoryAPI(clientId)
        dispatch({ type: FETCH_BILLING_HISTORY_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_BILLING_ERROR, payload: error.message });
    }
};

// Fetch client data action
export const fetchClientData = (clientId) => async (dispatch) => {
    try {
        const response = await fetchClientDataAPI(clientId);
        dispatch({ type: FETCH_CLIENT_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_CLIENT_ERROR, payload: error.message });
    }
};
