import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  IconButton,
  Button,
  Typography,
  Tooltip,
  LinearProgress,
  InputAdornment,
  Alert
} from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  Add as AddIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Preview as PreviewIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import { getContactLists, uploadCsv } from '../actions/contactActions';
import { fetchContactsFromList, retryContactUpload } from '../services/apiService';
import CsvPreviewModal from './modals/CsvPreviewModal';
import CsvUploadModal from './modals/CsvUploadModal';
import ProviderSelector from './helper/ProviderSelector';
import ViewContactsModal from './modals/ViewContactsModal';

const ContactList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedList, setSelectedList] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [viewContactsOpen, setViewContactsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const dispatch = useDispatch();
  const { lists: contactLists, loading: isLoading, error } = useSelector((state) => state.contact || { lists: [], loading: false, error: null });
  const currentProvider = localStorage.getItem('provider') || 'plivo';

  useEffect(() => {
    dispatch(getContactLists());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    dispatch(getContactLists());
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? 'Invalid Date' : format(date, 'MMM dd, yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const getListData = (list) => {
    try {
      if (currentProvider === 'plivo') {
        return {
          id: list._id || 'N/A',
          name: list.name || 'Untitled List',
          totalContacts: list.contactCount || 0,
          createdAt: list.createdAt || 'N/A'
        };
      } else {
        // Exotel data structure
        return {
          id: list._id || 'N/A',
          name: list.list_name || 'Untitled List',
          totalContacts: list.listSize || list.response?.list?.contact_count || 0,
          createdAt: list.response?.list?.date_created || 'N/A'
        };
      }
    } catch (error) {
      console.error('Error processing list data:', error);
      return {
        id: 'Error',
        name: 'Error processing data',
        totalContacts: 0,
        createdAt: 'N/A'
      };
    }
  };

  const filteredLists = useMemo(() => {
    if (!Array.isArray(contactLists)) return [];
    const searchTermLower = searchTerm.toLowerCase();
    return contactLists.filter(list => {
      return list._id?.toLowerCase().includes(searchTermLower) ||
             list.name?.toLowerCase().includes(searchTermLower);
    });
  }, [contactLists, searchTerm]);

  const handleViewCsvModalOpen = async (listId) => {
    try {
      const response = await fetchContactsFromList(listId);
      if (currentProvider === 'plivo') {
        setSelectedList(response.data);
      } else {
        // Handle Exotel response structure
        setSelectedList(response.data?.response || []);
      }
      setPreviewModalOpen(true);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleRetryUpload = async (listId) => {
    if (currentProvider !== 'exotel') return;
    
    try {
      await retryContactUpload(listId);
      // Refresh the list after retry
      dispatch(getContactLists());
    } catch (error) {
      console.error('Error retrying upload:', error);
    }
  };

  const handleUploadSuccess = async (formData, message) => {
    // Immediately refresh the contact lists
    await dispatch(getContactLists());
    setSuccessMessage('Contact list uploaded successfully!');
    // Clear success message after 3 seconds
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };

  const handleViewContacts = (list) => {
    setSelectedList(list);
    setViewContactsOpen(true);
  };

  const handlePreview = (list) => {
    console.log('Previewing list:', list);
    // Pass the entire list object
    setSelectedList(list);
    setPreviewModalOpen(true);
  };

  const handleDelete = (listId) => {
    // Add your handleDelete logic here
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ color: '#5f6368', fontWeight: 500 }}>
          Contact Lists
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <ProviderSelector sx={{ 
            '& .MuiSelect-select': { 
              color: '#5f6368'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.08)'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.12)'
            }
          }} />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setUploadModalOpen(true)}
            sx={{
              backgroundColor: '#2E7D32',
              '&:hover': {
                backgroundColor: '#1B5E20',
              },
            }}
          >
            Upload CSV
          </Button>
          <IconButton onClick={handleRefresh} color="primary">
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {successMessage && (
        <Alert 
          severity="success"
          onClose={() => setSuccessMessage('')}
          sx={{ 
            mb: 2,
            backgroundColor: 'rgba(46, 125, 50, 0.1)',
            color: '#2E7D32',
            '& .MuiAlert-icon': {
              color: '#2E7D32'
            }
          }}
        >
          {successMessage}
        </Alert>
      )}
      <Box sx={{ 
        mb: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}>
        <TextField
          size="small"
          placeholder="Search by name or ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: 300,
            bgcolor: '#ffffff',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.08)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.12)',
              },
              '& input': {
                color: '#5f6368',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#5f6368',
            },
            '& .MuiInputBase-input::placeholder': {
              color: '#5f6368',
              opacity: 0.7,
            },
          }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: '#5f6368', mr: 1 }} />,
          }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ 
        bgcolor: '#FFFFFF',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        overflow: 'hidden'
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#5f6368', fontWeight: 600 }}>List ID</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 600 }}>Name</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 600 }}>Total Contacts</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 600 }}>Created At</TableCell>
              <TableCell sx={{ color: '#5f6368', fontWeight: 600 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <LinearProgress sx={{ 
                    my: 2,
                    '& .MuiLinearProgress-bar': {
                      background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
                    }
                  }} />
                </TableCell>
              </TableRow>
            ) : !Array.isArray(contactLists) || contactLists.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No contact lists found
                </TableCell>
              </TableRow>
            ) : (
              filteredLists
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(list => {
                  const listData = getListData(list);
                  return (
                    <TableRow key={listData.id}>
                      <TableCell sx={{ color: '#5f6368' }}>{listData.id}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{listData.name}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{listData.totalContacts}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>
                        {formatDate(listData.createdAt)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handlePreview(list)}
                          sx={{
                            color: '#5f6368',
                            '&:hover': {
                              color: '#2E7D32',
                            },
                          }}
                        >
                          <PreviewIcon />
                        </IconButton>
                        {/* <IconButton
                          onClick={() => handleDelete(list._id)}
                          sx={{
                            color: '#5f6368',
                            '&:hover': {
                              color: '#d32f2f',
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredLists.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ 
            color: '#5f6368',
            bgcolor: '#F3F4F6',
            '.MuiTablePagination-select': {
              color: '#5f6368'
            },
            '.MuiTablePagination-selectIcon': {
              color: '#5f6368'
            }
          }}
        />
      </TableContainer>

      <CsvUploadModal
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        onUpload={handleUploadSuccess}
        provider={currentProvider}
      />
      <CsvPreviewModal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        contacts={selectedList}  // Pass the entire list object
      />
      <ViewContactsModal
        open={viewContactsOpen}
        onClose={() => setViewContactsOpen(false)}
        listId={selectedList?.id}
        listName={selectedList?.name}
      />
    </Box>
  );
};

export default ContactList;
