const initialState = {
    error: null,
  };
  
  export default function customerReducer (state = initialState, action) {
    switch (action.type) {
      case 'ADD_CUSTOMER_SUCCESS':
        return { ...state };
      case 'ADD_CUSTOMER_FAILURE':
        return { ...state, error: action.error };
      default:
        return state;
    }
  };
  