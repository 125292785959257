import { combineReducers } from 'redux';
import contactReducer from './contactReducer';
import campaignReducer from './campaignReducer';
import reportReducer from './reportReducer';
import authReducer from './authReducer';
import billingReducer from './billingReducer';
import incomingReducer from './incomingReducer';
import customerReducer from './customerReducer';
import providerReducer from './providerReducer';

export default combineReducers({
  contact: contactReducer,
  auth: authReducer,
  campaign: campaignReducer,
  report: reportReducer,
  billing: billingReducer,
  calls: incomingReducer,
  customers: customerReducer,
  provider: providerReducer
});
