import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CampaignIcon from '@mui/icons-material/Campaign';
import WarningIcon from '@mui/icons-material/Warning';
import { createCampaign, getCampaigns, getAssistants } from '../../actions/campaignActions';
import { getContactLists } from '../../actions/contactActions';
import { fetchClientInfo } from '../../services/apiService';

const formControlStyles = {
  width: '100%',
  marginBottom: 2,
  '& .MuiInputLabel-root': {
    color: '#6B7280',
  },
  '& .MuiInputBase-root': {
    color: '#6B7280',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#6B7280',
  },
  '& .MuiSelect-select': {
    color: '#6B7280',
  },
  '& .MuiMenuItem-root': {
    color: '#6B7280',
  },
};

const AlertBox = ({ icon: Icon, title, message, buttonText, onButtonClick, showButton = true }) => (
  <Paper 
    elevation={3} 
    sx={{ 
      p: 3, 
      mb: 3, 
      backgroundColor: 'white',
      borderRadius: 2,
      textAlign: 'center'
    }}
  >
    <Icon sx={{ 
      fontSize: 48, 
      mb: 2,
      color: '#2196f3'
    }} />
    <Typography variant="h6" sx={{ mb: 1, color: '#333' }}>
      {title}
    </Typography>
    <Typography sx={{ mb: 2, color: '#666' }}>
      {message}
    </Typography>
    {showButton && (
      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{
          bgcolor: '#2196f3',
          color: 'white',
          '&:hover': {
            bgcolor: '#1976d2',
          }
        }}
      >
        {buttonText}
      </Button>
    )}
  </Paper>
);

export default function StartCampaignModal({ open, onClose }) {
  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');

  const [formData, setFormData] = useState({
    campaignName: '',
    listId: '',
    fromNumber: '',
    assistantId: '',
    wssUrl: ''
  });
  const [assistants, setAssistants] = useState([]);
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [showBalanceCheck, setShowBalanceCheck] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));

    // Set wssUrl when assistant is selected
    if (field === 'assistantId') {
      const selectedAssistant = assistants.find(a => a._id === value);
      if (selectedAssistant?.wssUrl) {
        setFormData(prev => ({ ...prev, wssUrl: selectedAssistant.wssUrl }));
      } else {
        // If no wssUrl in assistant, use default
        setFormData(prev => ({ 
          ...prev, 
          wssUrl: 'null'
        }));
      }
    }
  };

  // Fetch assistants, lists and client info when modal opens
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Fetch client info first
        const clientResponse = await fetchClientInfo(auth._id);
        setClientInfo(clientResponse);
        setShowBalanceCheck(true);

        // Continue with other fetches
        const assistantsResponse = await dispatch(getAssistants());
        if (assistantsResponse?.data) {
          const filteredAssistants = assistantsResponse.data.filter(
            (assistant) => assistant.wssUrl
          );
          setAssistants(filteredAssistants);
        }

        const listsResponse = await dispatch(getContactLists());
        if (listsResponse?.data) {
          setLists(listsResponse.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load required data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [dispatch, open, auth._id]);

  const handleStartCampaign = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // First check client info
      const clientResponse = await fetchClientInfo(auth._id);
      setClientInfo(clientResponse);

      if (clientResponse.activeCampId) {
        setError('Cannot start a new campaign while another campaign is active.');
        setIsLoading(false);
        return;
      }

      if (!clientResponse.availableBalance || clientResponse.availableBalance < 50) {
        setError('Insufficient balance. Please contact Markaible administrative.');
        setIsLoading(false);
        return;
      }

      // Show confirmation dialog
      setShowConfirmation(true);
      setIsLoading(false);
    } catch (err) {
      console.error('Error checking campaign conditions:', err);
      setError(err.message || 'Failed to check campaign conditions. Please try again.');
      setIsLoading(false);
    }
  };

  const handleConfirmStart = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const campaignData = {
        ...formData,
        clientId: auth._id,
        wssUrl: formData.wssUrl || 'null'
      };

      await dispatch(createCampaign(campaignData));
      await dispatch(getCampaigns());
      setShowConfirmation(false);
      onClose();
    } catch (err) {
      console.error('Error starting campaign:', err);
      setError(err.message || 'Failed to start campaign. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleContactAdmin = () => {
    window.location.href = 'mailto:dev@markaible.com';
  };

  const isFormValid = () => {
    return formData.campaignName && 
           formData.listId && 
           formData.fromNumber && 
           formData.assistantId;  
  };

  if (!showBalanceCheck) {
    return null;
  }

  // Show alert boxes if there are conditions to check
  if (clientInfo?.activeCampId || !clientInfo?.availableBalance || clientInfo?.availableBalance < 50) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#f5f5f5',
            padding: '20px'
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" sx={{ color: '#333' }}>Campaign Status Check</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {/* Active Campaign Alert */}
          {clientInfo?.activeCampId && (
            <AlertBox
              icon={CampaignIcon}
              title="Active Campaign Running"
              message="You already have an active campaign. Please wait for it to complete before starting a new one."
              showButton={false}
            />
          )}

          {/* Available Balance Alert */}
          <AlertBox
            icon={AccountBalanceWalletIcon}
            title="Current Balance"
            message={`Your available balance is ${clientInfo?.availableBalance || 0}`}
            showButton={false}
          />

          {/* Minimum Balance Alert */}
          {(!clientInfo?.availableBalance || clientInfo?.availableBalance < 50) && (
            <AlertBox
              icon={WarningIcon}
              title="Insufficient Balance"
              message="A minimum balance of 50 is required to start a campaign. Please contact Markaible administrative to add funds."
              buttonText="Contact Admin"
              onButtonClick={handleContactAdmin}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Show the regular campaign form if all conditions are met
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#FAF9F6',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography sx={{ color: '#6B7280' }}>Start Campaign</Typography>
            <IconButton onClick={onClose} sx={{ color: '#6B7280' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            {/* Campaign Name */}
            <TextField
              label="Campaign Name"
              variant="outlined"
              fullWidth
              value={formData.campaignName}
              onChange={handleInputChange('campaignName')}
              sx={{ mb: 3 }}
              InputLabelProps={{ style: { color: '#6B7280' } }}
              InputProps={{ style: { color: '#6B7280' } }}
            />

            {/* Assistant Selection */}
            <FormControl fullWidth variant="outlined" sx={{ ...formControlStyles, mb: 3 }}>
              <InputLabel>Select Assistant</InputLabel>
              <Select
                value={formData.assistantId}
                onChange={handleInputChange('assistantId')}
                label="Select Assistant"
              >
                {assistants.map((assistant) => (
                  <MenuItem key={assistant._id} value={assistant._id}>
                    {assistant.name || assistant.agent_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Contact List Selection */}
            <FormControl fullWidth variant="outlined" sx={{ ...formControlStyles, mb: 3 }}>
              <InputLabel>Select Contact List</InputLabel>
              <Select
                value={formData.listId}
                onChange={handleInputChange('listId')}
                label="Select Contact List"
              >
                {lists.map((list) => (
                  <MenuItem key={list._id} value={list._id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Caller Number Selection */}
            <FormControl fullWidth variant="outlined" sx={formControlStyles}>
              <InputLabel>Select Caller Number</InputLabel>
              <Select
                value={formData.fromNumber}
                onChange={handleInputChange('fromNumber')}
                label="Select Caller Number"
              >
                {auth.callerNumbers?.map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button
            variant="contained"
            onClick={handleStartCampaign}
            disabled={isLoading || !isFormValid()}
            sx={{
              bgcolor: '#2E7D32',
              color: 'white',
              '&:hover': {
                bgcolor: '#1B5E20',
              },
              '&:disabled': {
                bgcolor: '#A5D6A7',
              }
            }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Start Campaign'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#ffffff',
          },
        }}
      >
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <AccountBalanceWalletIcon 
            sx={{ 
              fontSize: 64, 
              color: '#2196f3',
              mb: 2
            }} 
          />
          
          <Typography variant="h5" sx={{ mb: 2, color: '#333' }}>
            Confirm Campaign Start
          </Typography>

          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              mb: 3, 
              backgroundColor: '#f8f9fa',
              borderRadius: 2
            }}
          >
            <Typography variant="h6" sx={{ mb: 1, color: '#666' }}>
              Available Balance
            </Typography>
            <Typography variant="h4" sx={{ color: '#2196f3', fontWeight: 'bold' }}>
              {clientInfo?.availableBalance || 0}
            </Typography>
          </Paper>

          <Typography sx={{ mb: 3, color: '#666' }}>
            Are you sure you want to start this campaign?
            <br />
            Campaign Name: <strong>{formData.campaignName}</strong>
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmation(false)}
              sx={{ minWidth: 120 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmStart}
              disabled={isLoading}
              sx={{
                minWidth: 120,
                bgcolor: '#2196f3',
                '&:hover': {
                  bgcolor: '#1976d2',
                }
              }}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Start Campaign'}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}