import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const ChatModal = ({ open, onClose, chat }) => {
  const formatMessages = (chatString) => {
    if (!chatString) return [];
    
    return chatString.split('|')
      .map(msg => msg.trim())
      .filter(msg => msg)
      .map(msg => {
        if (msg.startsWith('human:')) {
          return {
            role: 'user',
            content: msg.replace('human:', '').trim()
          };
        } else if (msg.startsWith('AI:')) {
          return {
            role: 'ai',
            content: msg.replace('AI:', '').trim()
          };
        }
        // Handle messages without explicit role prefix
        const [role, ...contentParts] = msg.split(':');
        if (contentParts.length > 0) {
          return {
            role: role.toLowerCase().includes('human') ? 'user' : 'ai',
            content: contentParts.join(':').trim()
          };
        }
        return null;
      })
      .filter(msg => msg && msg.content);
  };

  const messages = formatMessages(chat);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          backgroundColor: '#faf8f6'
        }
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          backgroundColor: '#ffffff',
          p: 2
        }}
      >
        <Typography variant="h6" sx={{ color: '#5f6368', fontWeight: 500 }}>
          Chat History
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{ 
            color: '#5f6368',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent 
        sx={{ 
          p: 3, 
          backgroundColor: '#ffffff',
          minHeight: '300px',
          maxHeight: '70vh',
          overflowY: 'auto'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start',
                width: '100%'
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  maxWidth: '70%',
                  p: 2,
                  borderRadius: 2,
                  backgroundColor: msg.role === 'user' 
                    ? 'rgba(25, 118, 210, 0.08)'
                    : 'rgba(46, 125, 50, 0.08)',
                  color: '#5f6368',
                  wordBreak: 'break-word'
                }}
              >
                <Typography 
                  variant="body2"
                  sx={{ 
                    whiteSpace: 'pre-wrap',
                    fontFamily: msg.content.match(/[\u0900-\u097F]/) ? 
                      'Arial, sans-serif' : 'inherit'
                  }}
                >
                  {msg.content}
                </Typography>
              </Paper>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChatModal;
