import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Button,
  TablePagination,
  LinearProgress,
  Typography,
  InputAdornment,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Add as AddIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { getCampaigns } from '../actions/campaignActions';
import StartCampaignModal from './modals/StartCampaignModal';
import ProviderSelector from './helper/ProviderSelector';

const Campaign = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [startModalOpen, setStartModalOpen] = useState(false);
  const { campaigns, loading } = useSelector((state) => state.campaign);

  useEffect(() => {
    dispatch(getCampaigns());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    dispatch(getCampaigns());
  };

  const getCampaignData = (campaign) => {
    try {
      const currentProvider = localStorage.getItem('provider') || 'plivo';
      
      if (currentProvider === 'plivo') {
        return {
          id: campaign._id,
          name: campaign.campaignName,
          listId: campaign.listId,
          createdAt: campaign.createdAt
        };
      } else {
        // For Exotel
        const data = JSON.parse(campaign.responseFromCamp)?.response?.[0]?.data;
        return {
          id: campaign._id,
          name: data?.name || 'N/A',
          listId: data?.lists?.join(', ') || 'No lists',
          createdAt: data?.date_created || 'N/A'
        };
      }
    } catch (error) {
      console.error('Error processing campaign data:', error);
      return {
        id: campaign._id || 'N/A',
        name: 'Error processing data',
        listId: 'N/A',
        createdAt: 'N/A'
      };
    }
  };

  const filteredCampaigns = Array.isArray(campaigns) ? campaigns.filter(campaign => {
    try {
      const currentProvider = localStorage.getItem('provider') || 'plivo';
      if (currentProvider === 'plivo') {
        return campaign.campaignName?.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        const data = JSON.parse(campaign.responseFromCamp)?.response?.[0]?.data;
        return data?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      }
    } catch (error) {
      console.error('Error filtering campaign:', error);
      return false;
    }
  }) : [];

  return (
    <Box sx={{ p: 3, bgcolor: '#F9FAFB' }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2,
      }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            size="small"
            placeholder="Search campaigns..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'grey' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              width: 300,
              mb: 3,
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#ffffff',
                '& input': {
                  color: 'grey',
                },
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#D1D5DB',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2E7D32',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'grey',
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
                opacity: 1,
              },
            }}
          />
          <IconButton onClick={handleRefresh}>
            <RefreshIcon sx={{ color: 'grey' }} />
          </IconButton>
          <IconButton>
            <FilterIcon sx={{ color: 'grey' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ProviderSelector />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setStartModalOpen(true)}
            sx={{
              background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
              color: 'white',
              boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
              },
            }}
          >
            Start Campaign
          </Button>
        </Box>
      </Box>

      {/* Table */}
      <TableContainer component={Paper} sx={{ 
        bgcolor: '#FFFFFF',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        overflow: 'hidden'
      }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: '#F3F4F6' }}>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>Campaign ID</TableCell>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>Campaign Name</TableCell>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>List ID</TableCell>
              <TableCell sx={{ color: 'grey', fontWeight: 600 }}>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <LinearProgress sx={{ 
                    my: 2,
                    '& .MuiLinearProgress-bar': {
                      background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
                    }
                  }} />
                </TableCell>
              </TableRow>
            ) : !Array.isArray(campaigns) || campaigns.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography sx={{ color: 'grey', py: 3 }}>
                    No campaigns found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredCampaigns
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((campaign) => {
                  const campaignData = getCampaignData(campaign);
                  return (
                    <TableRow 
                      key={campaignData.id} 
                      hover
                      sx={{ 
                        '&:hover': { 
                          bgcolor: '#F9FAFB' 
                        }
                      }}
                    >
                      <TableCell sx={{ color: 'grey' }}>{campaignData.id}</TableCell>
                      <TableCell sx={{ color: 'grey' }}>{campaignData.name}</TableCell>
                      <TableCell sx={{ color: 'grey' }}>{campaignData.listId}</TableCell>
                      <TableCell sx={{ color: 'grey' }}>
                        {format(new Date(campaignData.createdAt), 'MMM dd, yyyy HH:mm')}
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCampaigns.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ 
            color: 'grey',
            bgcolor: '#F3F4F6',
            '.MuiTablePagination-select': {
              color: 'grey'
            },
            '.MuiTablePagination-selectIcon': {
              color: 'grey'
            }
          }}
        />
      </TableContainer>

      {/* Start Campaign Modal */}
      <StartCampaignModal
        open={startModalOpen}
        onClose={() => setStartModalOpen(false)}
      />
    </Box>
  );
};

export default Campaign;
