import axios from 'axios';
import { useState } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const API_BASE_URL = 'https://application.glimpass.com';

const getAuthToken = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  return auth ? auth.token : null;
};

const getClientId = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  return auth ? auth._id : null;
};

const apiGlimpass = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'Origin': 'https://app.markaible.com',
    'Referer': 'https://app.markaible.com/',
  },
});

const apiGlimpassInterlogue = axios.create({
  baseURL: API_BASE_URL+'/interlogue',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'Origin': 'https://app.markaible.com',
    'Referer': 'https://app.markaible.com/',
  },
});

apiGlimpass.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const apiGlimpassExotel = axios.create({
  baseURL: 'https://application.glimpass.com/exotel',
});

const apiGlimpassPlivo = axios.create({
  baseURL: 'https://application.glimpass.com/plivo',
});

// Helper function to get the correct API instance based on provider
export const getProviderApi = () => {
  const provider = localStorage.getItem('provider') || 'plivo';
  return provider === 'plivo' ? apiGlimpassPlivo : apiGlimpassExotel;
};

// Auth APIs
export const login = (credentials) => apiGlimpassInterlogue.post('/get-client', credentials);
export const register = (userData) => apiGlimpass.post('/register', userData);
export const createAssistant = (assistantData) => apiGlimpass.post('/create-assistant', assistantData);
export const getAssistants = (clientId) => apiGlimpassInterlogue.post('/get-assistant-by-client', {_id: clientId, isClient: 1});

// Contact List APIs
export const fetchContactLists = async (clientId) => {
  const api = getProviderApi();
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = provider === 'plivo' ? '/get-list-by-clientId' : '/get-list-by-clientId';

  try {
    const response = await api.post(endpoint, { clientId });
    const data = response.data;

    if (provider === 'plivo') {
      return data.map(list => ({
        _id: list._id || '--',
        list_name: list.name || '--',
        listSid: list._id || '--',
        clientId: list.clientId || '--',
        response: {
          list: {
            sid: list._id || '--',
            date_created: list.createdAt || '--',
            date_updated: list.createdAt || '--',
            account_sid: '--',
            name: list.name || '--',
            uri: '--',
            tag: '--',
            contact_count: 0
          },
          summary: {
            upload_id: '--',
            list_sid: list._id || '--',
            url: '--',
            accountSid: '--',
            date_created: list.createdAt || '--',
            date_updated: list.createdAt || '--',
            status: 'completed',
            stats: {
              duplicate: 0,
              total: 0,
              success: 0,
              failed: 0
            }
          }
        },
        listSize: 0
      }));
    } else {
      // For Exotel, ensure we're not overwriting any existing data with '--'
      return data.map(list => ({
        ...list,
        response: {
          list: {
            ...list.response.list,
            name: list.list_name || list.response.list.name,
            contact_count: list.listSize || list.response.list.contact_count || 0
          },
          summary: {
            ...list.response.summary,
            stats: {
              duplicate: list.response.summary.stats.duplicate || 0,
              total: list.response.summary.stats.total || 0,
              success: list.response.summary.stats.success || 0,
              failed: list.response.summary.stats.failed || 0
            }
          }
        }
      }));
    }
  } catch (error) {
    console.error('Error in fetchContactLists:', error);
    throw error;
  }
};

export const fetchContactListsNew = (clientId) => {
  return apiGlimpass.post('/get-list-by-client', { clientId });
};

export const fetchContactsFromList = async (listSid) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = provider === 'plivo' ? '/get-contacts-from-list' : '/get-contacts-from-list';
  const api = getProviderApi();
  try {
    const response = await api.post(endpoint, { listSid });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadCsvFile = async (formData) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = provider === 'plivo' ? '/upload-csv' : '/upload-csv';
  const api = getProviderApi();
  
  // Ensure required fields are in formData
  if (!formData.get('listName') || !formData.get('clientId')) {
    throw new Error('Missing required fields: listName or clientId');
  }

  try {
    const response = await api.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;  // Return full response to check status
  } catch (error) {
    throw error;
  }
};

export const createCampaign = async (campaignData) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = '/create-campaign';
  const api = getProviderApi();

  // Transform campaignData based on provider
  const payload = provider === 'plivo' ? {
    listId: campaignData.listId,
    fromNumber: campaignData.fromNumber,
    appId: campaignData.wssUrl, // Use appId instead of wssUrl for Plivo
    campaignName: campaignData.campaignName,
    clientId: campaignData.clientId
  } : campaignData;

  try {
    const response = await api.post(endpoint, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCampaignsByClient = async (clientId) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = provider === 'plivo' ? '/get-campaign-by-client' : '/get-camp-by-clientId';
  
  // const endpoint = '/get-campaign-by-client';
  const api = getProviderApi();

  try {
    const response = await api.post(endpoint, { clientId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportByCampaign = async (campaignId, campaignName) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = provider === 'plivo' ? '/get-report-by-campaign' : '/get-call-report-by-campaign';
  const api = getProviderApi();
  const auth = JSON.parse(localStorage.getItem('auth'));

  try {
    const response = await api.post(endpoint, {
      clientId: auth._id,
      campaignId:campaignId,
      camp_name: campaignName
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign report:', error);
    throw error;
  }
};

export const getReportByCampaignOld = async (campaignId) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = '/get-report-by-campaign';
  const api = getProviderApi();

  try {
    const response = await api.post(endpoint, { camp_id: campaignId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Campaign APIs
export const getCampaignsApi = async () => {
  const api = getProviderApi();
  const currentProvider = localStorage.getItem('provider') || 'plivo';
  const endpoint = currentProvider === 'plivo' ? '/get-campaign-by-client' : '/get-camp-by-clientId';
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }
    const response = await api.post(endpoint, { clientId: auth._id });
    return response;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const getContactListsApi = async () => {
  const api = getProviderApi();
  const currentProvider = localStorage.getItem('provider') || 'plivo';
  const endpoint = '/get-list-by-clientId'; // Same endpoint for both providers
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }
    const response = await api.post(endpoint, { clientId: auth._id });
    return response;
  } catch (error) {
    console.error('Error fetching contact lists:', error);
    throw error;
  }
};

export const getCampaignDetailsApi = async (campaignId) => {
  const api = getProviderApi();
  return api.post('/get-camp-details', { campaignId });
};

// Report APIs
export const getReportApi = async (data) => {
  const api = getProviderApi();
  return api.post('/get-report', data);
};

// Customer APIs
export const addCustomerApi = async (customerData) => {
  const api = getProviderApi();
  return api.post('/add-customer', customerData);
};

export const fetchClientData = (clientId) => {
  return apiGlimpass.post('/get-client-by-clientId',{clientId});
};

export const getAssistantByClient = async () => {
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }
    const response = await axios.post(`${API_BASE_URL}/assistant/get-assistant-by-client`, {
      clientId: auth._id
    });
    return response;
  } catch (error) {
    console.error('Error fetching assistant:', error);
    throw error;
  }
};

export const getContactListsByClient = async () => {
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (!auth || !auth._id) {
      throw new Error('Authentication data not found');
    }
    const response = await axios.post(`${API_BASE_URL}/contact/get-list-by-clientId`, {
      clientId: auth._id
    });
    return response;
  } catch (error) {
    console.error('Error fetching contact lists:', error);
    throw error;
  }
};

export const fetchAssistantsByClient = (_id) => {
  return apiGlimpass.post('/get-assistant-by-client',{_id, isClient:1});
};

export const updateAssistantDetails = async (assistantId, agentFirstMessage, systemPrompt) => {
  try {
    const response = await axios.post('https://application.glimpass.com/interlogue/update-assistant', {
      assistantId: assistantId,
      isClient: 1, // Hardcoded as per your requirement
      newDocs: {
        agent_welcome_message: agentFirstMessage,
        system_prompt: systemPrompt
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating assistant details', error);
    throw error;
  }
};

export const addCustomer = (customerData) => { 
  return apiGlimpass.post('/add-customew',customerData);
};

export const fetchBillingHistory = async (clientId) => {
  try {
    const response = await axios.post('https://application.glimpass.com/exotel/get-billing-history-by-clientId', {
      clientId: clientId // Make sure clientId is properly passed in payload
    }, {
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching billing history:', error);
    throw error;
  }
};

export const fetchClientInfo = async (clientId) => {
  try {
    const response = await axios.post('https://application.glimpass.com/interlogue/get-client-by-clientId', {
      clientId: clientId // Make sure clientId is properly passed in payload
    }, {
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching client info:', error);
    throw error;
  }
};

export const fetchIncomingCalls = (phoneNumber) => { 
  return apiGlimpassExotel.post('/get-incoming-call-details',{phoneNumber});
};

export const retryContactUpload = async (listId) => {
  // Only available for Exotel
  return apiGlimpassExotel.post('/retry-upload/'+listId);
};

// Add billing and token related functions
export const getBillingDetails = async (clientId) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = '/get-billing-details';
  const api = getProviderApi();

  try {
    const response = await api.post(endpoint, { clientId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getToken = async (clientId) => {
  const provider = localStorage.getItem('provider') || 'plivo';
  const endpoint = '/get-token';
  const api = getProviderApi();

  try {
    const response = await api.post(endpoint, { clientId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Dashboard APIs
export const fetchDashboardStats = async () => {
  const clientId = localStorage.getItem('clientId');
  return await apiGlimpass.get(`/get-dashboard-stats/${clientId}`);
};

export const fetchCallTrends = async () => {
  const clientId = localStorage.getItem('clientId');
  return await apiGlimpass.get(`/get-call-trends/${clientId}`);
};

// AI APIs
export const fetchAIAgents = async () => {
  const clientId = localStorage.getItem('clientId');
  return await apiGlimpass.get(`/get-ai-agents/${clientId}`);
};

export const updateAIAgent = async (agentId, data) => {
  return await apiGlimpass.put(`/update-ai-agent/${agentId}`, data);
};

// Overview APIs
export const fetchOverviewData = async () => {
  const clientId = getClientId();
  return await apiGlimpass.post('/exotel/get-client-overview', {
    campaignId: "all campaign",
    clientId: clientId
  });
};

// Contact APIs
export const fetchContacts = async () => {
  const clientId = getClientId();
  return await apiGlimpass.post('/exotel/get-contacts', {
    clientId: clientId
  });
};

// AI Agents APIs
export const getAssistantsNew = async () => {
  const clientId = getClientId();
  return await apiGlimpass.get(`/ai/assistants/${clientId}`);
};

export const createAssistantNew = async (data) => {
  const clientId = getClientId();
  return await apiGlimpass.post('/ai/assistants', { ...data, clientId });
};

export const updateAssistantNew = async (assistantId, data) => {
  return await apiGlimpass.put(`/ai/assistants/${assistantId}`, data);
};

// Campaign APIs
export const fetchCampaignsNew = async () => {
  const clientId = getClientId();
  return await apiGlimpass.get(`/campaigns/${clientId}`);
};

export const createCampaignNew = async (data) => {
  const clientId = getClientId();
  return await apiGlimpass.post('/campaigns', { ...data, clientId });
};

export const updateCampaignNew = async (campaignId, data) => {
  return await apiGlimpass.put(`/campaigns/${campaignId}`, data);
};

// Billing APIs
export const fetchBillingStatsNew = async () => {
  const clientId = getClientId();
  return await apiGlimpass.get(`/billing/stats/${clientId}`);
};

// Provider Selection Component
export const ProviderSelector = () => {
  const [provider, setProvider] = useState(localStorage.getItem('provider') || 'plivo');

  const handleProviderChange = (event) => {
    const newProvider = event.target.value;
    localStorage.setItem('provider', newProvider);
    setProvider(newProvider);
    window.location.reload();
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup 
        row 
        value={provider} 
        onChange={handleProviderChange}
        sx={{
          '& .MuiFormControlLabel-root': {
            color: 'grey',
          },
          '& .MuiFormControlLabel-label': {
            color: 'grey',
          },
        }}
      >
        <FormControlLabel 
          value="plivo" 
          control={
            <Radio 
              sx={{
                color: 'grey',
                '&.Mui-checked': {
                  color: '#2E7D32',
                },
              }}
            />
          } 
          label="Plivo"
        />
        <FormControlLabel 
          value="exotel" 
          control={
            <Radio 
              sx={{
                color: 'grey',
                '&.Mui-checked': {
                  color: '#2E7D32',
                },
              }}
            />
          } 
          label="Exotel"
        />
      </RadioGroup>
    </FormControl>
  );
};

export const getContactsFromList = async (listId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/plivo/get-list-contact`, { listId });
    return response.data;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

export const getListContacts = async (listId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/plivo/get-list-contact`, { listId });
    return response.data;
  } catch (error) {
    console.error('Error fetching list contacts:', error);
    throw error;
  }
};

export default apiGlimpass;
