import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { fetchIncomingCalls } from '../services/apiService';
import { makeStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChatIcon from '@mui/icons-material/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../actions/customerActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import debounce from 'lodash/debounce';
import CustomLoader from './helper/CustomLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  filterSection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  filterItem: {
    flex: '1 1 15%',
    minWidth: '200px',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

const IncomingCalls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const incomingCalls = useSelector((state) => state.calls?.incomingCalls || []);
  const loading = useSelector((state) => state.calls.loading);
  const errorMessage = useSelector((state) => state.calls.error);

  // Persistent state using localStorage
  const [callNumbers, setCallNumbers] = useState(() => {
    const savedNumbers = localStorage.getItem('callNumbers');
    return savedNumbers ? JSON.parse(savedNumbers) : [];
  });
  
  const [selectedNumber, setSelectedNumber] = useState('');
  
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [callsPerPage] = useState(15);
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState([]);
const [Loading , setLoading] = useState(false);
  // Debounced search handler
  const debouncedSearch = debounce((value) => {
    setSearchInput(value);
  }, 300);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    e.persist();
    debouncedSearch(value);
  };

  // Memoized filtered calls with optimized filtering
  const filteredCalls = useMemo(() => {
    if (!Array.isArray(incomingCalls)) return [];
    
    const searchLower = searchInput.toLowerCase();
    return incomingCalls.filter(call => {
      if (!searchInput && !selectedNumber && !selectedDate) return true;
      
      const matchesSearch = !searchInput || (
        (call.CallSid && call.CallSid.toLowerCase().includes(searchLower)) ||
        (call.CallFrom && call.CallFrom.includes(searchInput)) ||
        (call.CallTo && call.CallTo.includes(searchInput))
      );

      const matchesNumber = !selectedNumber || (
        call.CallFrom === selectedNumber || 
        call.CallTo === selectedNumber
      );

      const matchesDate = !selectedDate || (
        new Date(call.Created).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
      );

      return matchesSearch && matchesNumber && matchesDate;
    });
  }, [incomingCalls, searchInput, selectedNumber, selectedDate]);

  // Save state to localStorage
  useEffect(() => {
    localStorage.setItem('callNumbers', JSON.stringify(callNumbers));
  }, [callNumbers]);

  // Fetch call numbers only once
  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    if (authData?.callerNumbers) {
      const numbers = authData.callerNumbers.map(num => 
        num.startsWith('+91') ? `0${num.slice(3)}` : num
      );
      setCallNumbers(numbers);
    }
    // Reset selected number when component mounts
    setSelectedNumber('');
  }, []);

  const handleNumberChange = (e) => {
    const number = e.target.value;
    setSelectedNumber(number);
    setCurrentPage(1);
    if (number) {
      dispatch(fetchIncomingCalls(number));
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentPage(1);
  };

  const handleCheckboxChange = (call) => {
    setSelectedRow(call);
    setAddCustomerModalOpen(true);
  };

  const handleAddCustomer = () => {
    if (selectedRow && customerName) {
      dispatch(addCustomer({
        ...selectedRow,
        name: customerName,
        addedBy: 'incomingCall'
      }));
      setAddCustomerModalOpen(false);
      setSelectedRow(null);
      setCustomerName('');
    }
  };

  const handleCopyToClipboard = (callSid) => {
    navigator.clipboard.writeText(callSid).then(() => {
      alert('Call SID copied to clipboard!');
    });
  };

  const handleChatOpen = (call) => {
    if (call.chat) {
      const chatMessages = call.chat.split('|').map(message => message.trim());
      setSelectedChat(chatMessages);
      setChatModalOpen(true);
    }
  };

  const handleChatClose = () => {
    setChatModalOpen(false);
    setSelectedChat([]);
  };

  // Pagination calculations
  const currentCalls = useMemo(() => {
    const indexOfLastCall = currentPage * callsPerPage;
    const indexOfFirstCall = indexOfLastCall - callsPerPage;
    return filteredCalls.slice(indexOfFirstCall, indexOfLastCall);
  }, [filteredCalls, currentPage, callsPerPage]);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const username = "d75decc332c73eefcca91de1d8e6d15a39c73a78e724d509"; // Replace with actual username
  const password = "2132f6aee53a8cb91511d05349c5890c2a017d70ece77ef3"; // Replace with actual password

  const formatChatMessage = (message) => {
    const parts = message.split(':');
    const sender = parts[0].trim();
    const text = parts.slice(1).join(':').split('>').map((line, index) => <div key={index}>{line.trim()}</div>);
    return { sender, text };
  };

  const [calls, setCalls] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const response = await fetchIncomingCalls();
      setCalls(response.data || []);
    } catch (error) {
      console.error('Error fetching calls:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  // const filteredCalls = useMemo(() => 
  //   calls.filter(call =>
  //     call.from?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     call.to?.toLowerCase().includes(searchTerm.toLowerCase())
  //   ),
  //   [calls, searchTerm]
  // );

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3,
      }}>
        <Typography variant="h5" sx={{ color: '#5f6368', fontWeight: 500 }}>
          Incoming Calls
        </Typography>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          sx={{
            background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
            color: 'white',
            boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
            '&:hover': {
              background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
            },
            textTransform: 'none',
          }}
        >
          Refresh
        </Button>
      </Box>

      <Box sx={{ 
        mb: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}>
        <TextField
          size="small"
          placeholder="Search calls..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: 300,
            bgcolor: '#ffffff',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.08)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.12)',
              },
            },
          }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
          }}
        />
        <IconButton 
          size="small" 
          sx={{ 
            bgcolor: '#ffffff',
            border: '1px solid rgba(0, 0, 0, 0.08)',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.02)',
            },
          }}
        >
          <FilterIcon />
        </IconButton>
      </Box>

      <Paper sx={{ 
        width: '100%',
        bgcolor: '#ffffff',
        borderRadius: 1,
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>Call ID</TableCell>
                <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>From</TableCell>
                <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>To</TableCell>
                <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>Duration</TableCell>
                <TableCell sx={{ fontWeight: 500, color: '#5f6368', bgcolor: '#f8f9fa' }}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : filteredCalls.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No calls found
                  </TableCell>
                </TableRow>
              ) : (
                filteredCalls
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((call) => (
                    <TableRow 
                      key={call.id}
                      sx={{ '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.02)' } }}
                    >
                      <TableCell sx={{ color: '#5f6368' }}>{call.id}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{call.from}</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{call.to}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            display: 'inline-block',
                            bgcolor: call.status === 'completed' ? 'rgba(46, 125, 50, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                            color: call.status === 'completed' ? '#2E7D32' : '#5f6368',
                            fontSize: '0.875rem',
                          }}
                        >
                          {call.status}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>{call.duration}s</TableCell>
                      <TableCell sx={{ color: '#5f6368' }}>
                        {format(new Date(call.timestamp), 'MMM dd, yyyy HH:mm')}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCalls.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: '1px solid rgba(0, 0, 0, 0.08)',
            bgcolor: '#ffffff',
          }}
        />
      </Paper>
    </Box>
  );
};

export default React.memo(IncomingCalls);
