import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { getContactsFromList } from '../../services/apiService';

const ViewContactsModal = ({ open, onClose, listId, listName }) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!open || !listId) return;

      try {
        setLoading(true);
        setError(null);
        const data = await getContactsFromList(listId);
        setContacts(data);
      } catch (err) {
        console.error('Error fetching contacts:', err);
        setError('Failed to load contacts. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, [open, listId]);

  const handleDownloadCSV = () => {
    if (!contacts || contacts.length === 0) return;

    // Get headers from first contact object
    const headers = Object.keys(contacts[0]);
    
    // Create CSV content
    const csvContent = [
      headers.join(','), // Header row
      ...contacts.map(contact => 
        headers.map(header => 
          // Wrap values in quotes and escape existing quotes
          `"${(contact[header] || '').toString().replace(/"/g, '""')}"`
        ).join(',')
      )
    ].join('\\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${listName || 'contacts'}_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#ffffff',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ color: '#666666', fontWeight: 500 }}>
            View Contacts - {listName}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadCSV}
              disabled={loading || contacts.length === 0}
              sx={{ 
                mr: 2,
                color: '#666666',
                borderColor: '#666666',
                '&:hover': {
                  borderColor: '#333333',
                  color: '#333333',
                }
              }}
            >
              Download CSV
            </Button>
            <IconButton onClick={onClose} sx={{ color: '#666666' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pb: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" textAlign="center">
            {error}
          </Typography>
        ) : contacts.length === 0 ? (
          <Typography textAlign="center" color="#666666">
            No contacts found in this list.
          </Typography>
        ) : (
          <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>Name</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>Phone</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>Email</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.slice(0, 10).map((contact, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: '#666666' }}>{contact.name || 'N/A'}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.phone || 'N/A'}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.email || 'N/A'}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.status || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {contacts.length > 10 && (
              <Box sx={{ p: 2, textAlign: 'center', color: '#666666' }}>
                Showing first 10 contacts. Download CSV to view all contacts.
              </Box>
            )}
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewContactsModal;
