import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { getListContacts } from '../../services/apiService';

const CsvPreviewModal = ({ open, onClose, contacts }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!open || !contacts?._id) return;

      try {
        setLoading(true);
        setError(null);
        const data = await getListContacts(contacts._id);
        setContactData(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error('Error fetching contacts:', err);
        setError('Failed to load contacts. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (open && contacts?._id) {
      fetchContacts();
    }
  }, [open, contacts]);

  const handleDownloadCSV = () => {
    if (!contactData || contactData.length === 0) return;

    // Define the fields we want to include in the CSV
    const fields = ['number', 'first_name', 'last_name', 'company_name', 'email', 'tag', 'custom_field'];
    
    // Create CSV content with Windows-style line endings
    let csvContent = fields.join(',') + '\\r\\n'; // Header row

    // Add data rows
    contactData.forEach(contact => {
      const row = fields.map(field => {
        const value = contact[field] || '';
        // Handle values that might contain commas or quotes
        if (value.includes(',') || value.includes('"') || value.includes('\\n')) {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      });
      csvContent += row.join(',') + '\\r\\n';
    });

    // Create and trigger download with BOM for Excel
    const BOM = '\\uFEFF';
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `contacts_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#ffffff',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ color: '#666666' }}>
            Contact List Preview
          </Typography>
          <Box>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadCSV}
              disabled={loading || contactData.length === 0}
              sx={{ 
                mr: 2,
                color: '#666666',
                borderColor: '#666666',
                '&:hover': {
                  borderColor: '#333333',
                  color: '#333333',
                }
              }}
            >
              Download CSV
            </Button>
            <IconButton onClick={onClose} sx={{ color: '#666666' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pb: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" textAlign="center">
            {error}
          </Typography>
        ) : contactData.length === 0 ? (
          <Typography textAlign="center" color="#666666">
            No contacts found in this list.
          </Typography>
        ) : (
          <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: '#ffffff' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>number</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>first_name</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>last_name</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>company_name</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>email</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>tag</TableCell>
                  <TableCell sx={{ color: '#666666', fontWeight: 500 }}>custom_field</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactData.slice(0, 10).map((contact, index) => (
                  <TableRow key={contact._id || index}>
                    <TableCell sx={{ color: '#666666' }}>{contact.number || ''}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.first_name || ''}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.last_name || ''}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.company_name || ''}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.email || ''}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.tag || ''}</TableCell>
                    <TableCell sx={{ color: '#666666' }}>{contact.custom_field || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {contactData.length > 10 && (
              <Box sx={{ p: 2, textAlign: 'center', color: '#666666' }}>
                Showing first 10 contacts. Download CSV to view all contacts.
              </Box>
            )}
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CsvPreviewModal;
