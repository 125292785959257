import {
  GET_CONTACT_LISTS,
  GET_CONTACT_LISTS_SUCCESS,
  GET_CONTACT_LISTS_FAILURE
} from '../actions/types';

const initialState = {
  lists: [],
  loading: false,
  error: null
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT_LISTS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CONTACT_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        lists: Array.isArray(action.payload) ? action.payload : [],
        error: null
      };
    case GET_CONTACT_LISTS_FAILURE:
      return {
        ...state,
        loading: false,
        lists: [],
        error: action.payload
      };
    default:
      return state;
  }
}
