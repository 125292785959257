import React, { useState, useCallback } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

const initialNodes = [];
const initialEdges = [];

const AIFlowBuilder = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nodeCount, setNodeCount] = useState(0);
  const history = useHistory();

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const addNode = useCallback(() => {
    const newNode = {
      id: `node-${nodeCount}`,
      data: { 
        label: `Node ${nodeCount}`,
        content: 'Click to edit...'
      },
      position: { x: 250, y: nodeCount * 100 },
      type: 'default',
      style: {
        background: '#ffffff',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        padding: '10px',
        width: 180,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        color: '#5f6368',
      },
    };
    setNodes((nds) => [...nds, newNode]);
    setNodeCount((count) => count + 1);
  }, [nodeCount]);

  return (
    <Box sx={{ 
      height: '100vh',
      width: '100%',
      bgcolor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{ 
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        bgcolor: '#ffffff',
      }}>
        <Typography variant="h6" sx={{ color: '#5f6368' }}>
          AI Flow Builder
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addNode}
            sx={{
              background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
              color: 'white',
              boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1B5E20 30%, #388E3C 90%)',
              },
              textTransform: 'none',
            }}
          >
            Add Node
          </Button>
          <IconButton 
            onClick={() => history.goBack()}
            sx={{ color: '#5f6368' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      
      <Box sx={{ flex: 1, position: 'relative' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          style={{ background: '#ffffff' }}
        >
          <Background
            color="#f0f0f0"
            gap={16}
            size={1}
            style={{ opacity: 0.3 }}
          />
          <Controls />
          <MiniMap
            style={{
              height: 120,
              backgroundColor: '#ffffff',
            }}
            nodeColor="#5f6368"
          />
        </ReactFlow>
      </Box>
    </Box>
  );
};

export default AIFlowBuilder;
