import { getProviderApi } from './apiService';

export const getContactListsApi = async (clientId) => {
  const currentProvider = localStorage.getItem('provider') || 'plivo';
  const api = getProviderApi();
  
  try {
    const response = await api.post('/get-list-by-clientId', { clientId });
    console.log('Contact Lists API Response:', response);
    return response;
  } catch (error) {
    console.error('Error in getContactListsApi:', error);
    throw error;
  }
};

export const uploadCsvApi = async (formData) => {
  const api = getProviderApi();
  return api.post('/upload-csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const retryContactUploadApi = async (listId) => {
  const api = getProviderApi();
  return api.post('/retry-contact-upload', { listId });
};
