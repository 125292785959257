// redux/reducer.js
const initialState = {
    incomingCalls: [],
    loading: false,
    error: null,
  };
  
  export default function incomingReducer (state = initialState, action) {
    switch (action.type) {
      case 'FETCH_CALLS_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_CALLS_SUCCESS':
        return { ...state, loading: false, incomingCalls: action.payload };
      case 'FETCH_CALLS_FAILURE':
        return { ...state, loading: false, error: action.error };
      case 'ADD_CUSTOMER_SUCCESS':
        return { ...state };
      case 'ADD_CUSTOMER_FAILURE':
        return { ...state, error: action.error };
      default:
        return state;
    }
  };
  