import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '70px',
    height: '50px',
    boxSizing: 'border-box',
    background: `
      conic-gradient(from 135deg at top,#0000, #fff 1deg 90deg,#0000 91deg) right -20px bottom 8px/18px 9px,
      linear-gradient(#fff 0 0) bottom/100% 8px,
      #000`,
    backgroundRepeat: 'no-repeat',
    borderBottom: '8px solid #000',
    position: 'relative',
    animation: '$loaderAnimation 2s infinite linear',
  },
  loaderBefore: {
    content: '""',
    position: 'absolute',
    width: '10px',
    height: '14px',
    backgroundColor: 'lightblue',
    left: '10px',
    animation: '$loaderBeforeAnimation 2s infinite cubic-bezier(0,200,1,200)',
  },
  '@keyframes loaderAnimation': {
    '100%': { backgroundPosition: 'left -20px bottom 8px, bottom' },
  },
  '@keyframes loaderBeforeAnimation': {
    '0%, 50%': { bottom: '8px' },
    '90%, 100%': { bottom: '8.1px' },
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
}));

const CustomLoader = ({ message = 'Loading...' }) => {
  const classes = useStyles();

  return (
    <Box className={classes.centered}>
      <Box className={classes.loader}>
        <Box className={classes.loaderBefore} />
      </Box>
      <Typography variant="h6" color="inherit" style={{ marginTop: '1rem' }}>
        {message}
      </Typography>
    </Box>
  );
};

export default CustomLoader;
